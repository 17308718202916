<script setup>
  import { isDateGreaterThanToday } from '@/views/pay/compontent/calculateTime.js'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['getCouponCodeSuc', 'clickCard'])

  const props = defineProps({
    item: {
      type: Object
    },
    coupon: {
      type: Object
    },
    type: {
      type: String
    }
  })

  // 领取优惠卷
  const recieveCoupon = (item, index) => {
    if (proxy.JLTools.isNull(userStore.userInfo.userId)) {
      return proxy.$router.push({
        path: '/login'
      })
    }

    if (isDateGreaterThanToday(props.coupon.beginAt || props.item.beginAt)) return

    api.coupon
      .recieveCoupon(item.id, userStore.userInfo.userId)
      .then((res) => {
        // console.log('领取成功')
        userStore.setCouponNum(userStore.couponNum + 1)
        proxy.$Message.confirm({
          type: 'succeed',
          title: proxy.$t('提示!'),
          msg: proxy.$t('您已领取了此券.赶快去使用吧!'),
          okText: 'Use',
          onOk: () => {
            $emit('getCouponCodeSuc')
            couponUse(item)
          },
          onCancel: () => {
            $emit('getCouponCodeSuc')
          }
        })
        // proxy.couponList[index].received = true
      })
      .catch((res) => {
        // console.log('领取失败')
        proxy.$toast(proxy.$t('已达到上限，不可领取！'))
      })
  }

  // 去使用
  const couponUse = (item) => {
    if (props.type === 'History' || isDateGreaterThanToday(props.coupon.beginAt || props.item.beginAt)) return
    let data = {}
    if (item.suitableProduct === 'ASSIGNDISABLED') {
      data = {
        couponType: 'notIn',
        productIds: item.productIds
      }
    } else if (item.suitableProduct === 'ASSIGNABLED') {
      data = {
        couponType: 'in',
        productIds: item.productIds
      }
    } else {
      data = {
        couponType: 'all'
      }
    }
    proxy.$router.push({
      name: 'productList',
      query: data
    })
  }

  // 优惠卷分享
  const couponShare = (item) => {
    if (props.type === 'History' || isDateGreaterThanToday(props.coupon.beginAt || props.item.beginAt)) return
    // console.log(props.item.shareCode, 'props.shareCode')
    if (props.item.shareCode) {
      // 已分享复制
      copyText(props.item.shareCode)
      return proxy.$toast(`Log in to Haier, enter the sharing code ${props.item.shareCode} to get a coupon`, { type: 'succeed' })
    }
    api.coupon
      .getCouponShare(item.id)
      .then((res) => {
        console.log('优惠卷分享', res)
        $emit('getCouponCodeSuc')
        copyText(props.item.shareCode, 'Coupon sharing codecopied to your clipboard')
      })
      .catch((res) => {
        proxy.$toast('The share code already exists', { type: 'error' })
      })
  }

  // 复制文本
  const copyText = (text, toastText) => {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    // 可以添加一些提示，告诉用户文本已经复制成功
    console.log('复制成功')
    if (toastText) {
      proxy.$toast(`${toastText}`, { type: 'succeed' })
    }
  }

  //数字转金额格式
  const forPrice = (value) => {
    //// console.log(value)
    if (!value) return 'Rs.0'
    var intPart = Number(value) | 0 //获取整数部分
    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
    // return 'Rs. ' + intPartFormat
    return `Rs.<span style="font-size: 16px">${intPartFormat}</span>`
  }

  const clickCard = (item) => {
    // console.log('clickCard')
    if (props.type === 'History' || isDateGreaterThanToday(props.coupon.beginAt || props.item.beginAt)) return
    $emit('clickCard', item)
  }
</script>

<template>
  <div
    class="rounded-div rowCC"
    :class="props.type === 'History' || isDateGreaterThanToday(props.coupon.beginAt || props.item.beginAt) ? 'history-box' : ''"
    @click="clickCard(props.coupon)"
  >
    <div class="card-content-box">
      <div class="card-content">
        <div class="card-small-box rowBS">
          <div class="card-left columnBS">
            <div>
              <div class="coupon-name">{{ props.coupon.name }}</div>
              <div class="coupon-type">{{ props.coupon.suitableProduct === 'ALLABLED' ? 'ALL PRODUCTS' : 'SPECIFIC PRODUCT' }}</div>
            </div>
            <div>
              <div v-if="props.coupon.durationDayNum || props.item.durationDayNum" class="data-duration-time">
                Valid for {{ props.coupon.durationDayNum || props.item.durationDayNum }} day(s) from the pick up
              </div>
              <div v-else-if="item.coupon?.countdown" class="data-duration-time">Time Left: {{ item.coupon?.countdown }}</div>
              <div v-else class="data-time">
                <div>START DATE: {{ JLTools.formatDateTime(props.item.beginAt || props.coupon.beginAt, 'DD/MM/YYYY') }}</div>
                <div>EXPIRE DATE: {{ JLTools.formatDateTime(props.item.endAt || props.coupon.endAt, 'DD/MM/YYYY') }}</div>
              </div>
            </div>
          </div>
          <div class="card-right" :class="props.type === 'buyDialog' ? 'columnCE' : 'columnBE'">
            <div class="num-box columnSC">
              <div class="discount-box">
                <div v-if="props.coupon?.couponType === 'PRODUCT_DISCOUNT'">
                  <div data-name="固定折扣" v-if="props.coupon.reduceAmount">
                    <span class="discount-text" v-html="forPrice(props.coupon.reduceAmount)"></span>
                    <span>OFF</span>
                  </div>
                  <div data-name="百分比折扣" v-else-if="props.coupon.discountRate">
                    <span class="discount-text num">{{ (props.coupon.discountRate * 100).toFixed(0) }}%</span>
                    <span>OFF</span>
                  </div>
                </div>
                <div v-if="props.coupon?.couponType === 'REDUCED_FREIGHT'" data-name="免运费">
                  {{ `${$t('freight.FreeFreight')}` }}
                </div>
              </div>
              <div class="min-spend">
                <span>{{ $t('Min.spend') }} {{ JLTools.forPrice(props.coupon.spendAmount) }}</span>
              </div>
            </div>
            <div class="coupon-btn-box" v-if="props.type !== 'buyDialog'">
              <div class="btn-box" v-if="props.type === 'collect'">
                <div class="btn-use rowCC" @click.stop="recieveCoupon(item)">Collect</div>
              </div>
              <div v-else>
                <div class="btn-box" v-if="props.coupon.cType === 2 && props.item.parentId === null" @click="couponShare(props.item)">
                  <div class="btn-use rowCC">
                    <div v-if="props.item.shareCode">
                      {{ props.item.shareCode }}
                    </div>
                    <div v-else>
                      Share
                      <i class="iconfont icon-fenxiang"></i>
                    </div>
                  </div>
                </div>
                <div class="rowSE" v-else>
                  <div class="share rowCC" v-if="props.coupon.cType === 1 && props.item.parentId === null" @click="couponShare(props.item)">
                    <i class="iconfont icon-fenxiang"></i>
                  </div>
                  <div class="btn-box">
                    <div class="btn-use rowCC" @click.stop="couponUse(props.coupon)">Use</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  :deep(.el-dialog) {
    border-radius: 20px;
  }
  .rounded-div {
    width: 100%;
    height: 168px;
    margin-top: 20px;
    background-color: #f0f0f0; /* 设置背景颜色 */
    --r: 20px;
    --mask: radial-gradient(var(--r) at var(--r) var(--r), #0000 98%, #000) -20px -20px;
    mask: var(--mask);
    .card-content-box {
      width: calc(100% - 42px);
      height: 100%;
      /* background: var(--btnBgColor); */
      background: linear-gradient(88.67deg, #ed8e00 1.03%, #e60000 98.97%);
      color: #fff;
      .card-content {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 10px;
        box-sizing: border-box;
        border: 3px solid #fff;
        .card-small-box {
          width: 100%;
          height: 100%;
          padding: 10px;
        }
        .card-left {
          flex: 1;
          height: 100%;
          overflow: hidden;
          font-weight: 600;
          .coupon-name {
            font-size: 30px;
            white-space: nowrap;
          }
          .coupon-type {
            width: fit-content;
            padding: 4px 12px;
            margin-top: 8px;
            font-size: 14px;
            /* color: var(--btnBgColor); */
            color: #000;
            border-radius: 30px;
            background: #fff;
          }
          .data-time {
            font-size: 12px;
          }
          .data-duration-time {
            width: 150px;
            font-size: 14px;
            word-wrap: break-word;
          }
        }
        .card-right {
          width: 84px;
          height: 100%;
          margin-left: 10px;
          .num-box {
            width: 84px;
            height: 78px;
            background: url('https://cdn.qingmeidata.com/0a4a9bb3.png') no-repeat;
            background-size: 100% 100%;
            color: rgba(34, 34, 34, 1);
            .discount-box {
              font-family: 'Big Shoulders Display';
              margin-top: 15px;
              height: 30px;
              line-height: 30px;
              font-weight: 600;
              text-align: center;
              white-space: nowrap;
            }
            .discount-text {
              font-size: 12px;
              margin-right: 3px;
            }
            .num {
              font-size: 16px;
            }
            .min-spend {
              margin-top: 14px;
              font-size: 8px;
              text-align: center;
            }
          }
          .coupon-btn-box {
            width: calc(100% - 3px);
            .share {
              width: 25px;
              height: 22px;
              margin-right: 6px;
              background: #fff;
              border-radius: 12px;
              cursor: pointer;
            }
            .btn-box {
              flex: 1;
              position: relative;
              width: calc(100% - 6px);
              height: 20px;
              margin-right: 3px;
              border: 1px solid #fff;
              border-radius: 10px;
              box-sizing: border-box;
              cursor: pointer;
              .btn-use {
                position: absolute;
                top: -3px;
                left: 3px;
                width: calc(100%);
                height: 100%;
                color: rgba(16, 11, 34, 1);
                background: #fff;
                border-radius: 10px;
                .icon-fenxiang {
                  margin-left: 5px;
                }
              }
            }
            .icon-fenxiang {
              font-size: 10px;
              color: #000;
            }
          }
        }
      }
    }
  }
  .history-box {
    opacity: 0.6;
  }
</style>
